import Vue from 'vue'
import 'normalize.css/normalize.css'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: () => import('../views/login/login.vue') },
  {
    path: '/home', redirect: '/welcome', component: () => import('../views/login/home.vue'),
    children: [
      { path: '/welcome', component: () => import('../views/login/welcome.vue') },
      { path: '/user/list', component: () => import('../views/user/user-list.vue') },
      { path: '/operate/gamelog', component: () => import('../views/operate/game-log.vue') },
      { path: '/game/conf', component: () => import('../views/game/conf.vue') },
      { path: '/game/flux', component: () => import('../views/game/flux.vue') },
  ]

  },
  
]

const router = new Router({
  routes
})

router.beforeEach((to, from, next) => {

  NProgress.start();
  if (to.path === '/login') {
    next();
    NProgress.done()
  } else {
    const tokenStr = window.sessionStorage.getItem("game-token");
    if (!tokenStr) {
      next('/login')
      NProgress.done()
    } else {
      next()
      NProgress.done()

    }
  }



})
router.afterEach(() => {
  NProgress.done()
});

export default router
